import * as React from "react"

import Header from "../components/header"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactPage = () => (
  <Layout>
    <Seo title="Home" />
    <Header />
    <div
      style={{        
        width: `70%`,
        margin: `var(--space-5) auto`
      }}
    >
      <form
        name="kontakt_V1"
        method="POST"
        action="/danke-kontakt"
        data-netlify="true"
        onSubmit="submit"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="kontakt_V1" />
        <p hidden>
          <label>
            Bitte nicht ausfüllen: <input name="bot-field" />
          </label>
        </p>

        <div className="field">
          <label className="label">Name/Vorname</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Vorname/Name"
              name="name"
            />
          </div>
        </div>

        <div className="field">
          <label className="label">Straße/Hausnummer</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Straße/Hausnummer"
              name="strasse"
            />
          </div>
        </div>

        <div className="field">
          <label className="label">PLZ/Ort</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="PLZ/Ort"
              name="plz-ort"
            />
          </div>
        </div>

        <div className="field">
          <label className="label">Telefon</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Telefon"
              name="telefon"
            />
          </div>
        </div>

        <div className="field">
          <label className="label">E-Mail</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="E-Mail"
              name="mail"
            />
          </div>
        </div>

        <div className="field">
          <label className="label">Ihre Nachricht</label>
          <div className="control">
            <textarea
              className="textarea"
              placeholder="Ihre Nachricht"
              name="nachricht"
            ></textarea>
          </div>
        </div>

        <div className="field">
          <div className="control">
            <button type="submit" className="button is-link is-medium">
              Senden
            </button>
          </div>
        </div>
      </form>
    </div>
  </Layout>
)

export default ContactPage
